<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <material-card
          color="#baa995"
          title="Produtos"
          text="Lista de todos os produtos"
        >
          <v-toolbar color="#FFF">
            <v-text-field
              hide-details
              prepend-icon
              single-line
              v-model="pesquisa"
            ></v-text-field>

            <v-spacer></v-spacer>

            <v-flex>
              <select
                id="selecao"
                v-model="tipodebusca"
                @change="this.changeSearch"
              >
                <option disabled value="">Selecione uma opção</option>
                <option id="selecao" value="code">Código de Barras</option>
                <option id="selecao" value="brand">Marca</option>
                <option id="selecao" value="name">Nome</option>
              </select>
            </v-flex>

            <v-checkbox v-model="fotos" :label="`Fotos`"></v-checkbox>

            <v-checkbox v-model="disponivel" :label="`Disponível`"></v-checkbox>

            <v-checkbox v-model="visivel" :label="`Visível`"></v-checkbox>

            <v-toolbar-items>
              <v-btn text color="#a9a9a9" @click="this.buscarTipo"
                >Buscar</v-btn
              >
            </v-toolbar-items>

            <v-item-group></v-item-group>
          </v-toolbar>
          <div v-if="pesquisa === ''">
            <v-data-table :headers="headers" :items="items">
              <template slot="headerCell" slot-scope="{ header }">
                <span
                  class="subheading font-weight-light text-success text--darken-3"
                  v-text="header.text"
                />
              </template>
              <template slot="items" slot-scope="{ item }">
                <td v-if="item.url">
                  <v-img :src="item.url"></v-img>
                </td>
                <td v-else>Sem imagem</td>
                <td>{{ item.nome }}</td>
                <td>
                  {{
                    item.preco.preco_venda
                      .toFixed(2)
                      .toString()
                      .replace(".", ",")
                  }}
                </td>
                <td>{{ item.marca }}</td>
                <td class="text-xs-right">{{ item.codigoDeBarras }}</td>

                <v-btn color="#000" @click="showProduto(item)">Ver</v-btn>
              </template>
            </v-data-table>
          </div>
          <div v-else>
            <v-data-table :headers="headers" :items="items_busca">
              <template slot="headerCell" slot-scope="{ header }">
                <span
                  class="subheading font-weight-light text-success text--darken-3"
                  v-text="header.text"
                />
              </template>
              <template slot="items" slot-scope="{ item }">
                <td v-if="item.url">
                  <v-img :src="item.url"></v-img>
                </td>
                <td v-else>Sem imagem</td>
                <td>{{ item.nome }}</td>
                <td>
                  {{
                    item.preco.preco_venda
                      .toFixed(2)
                      .toString()
                      .replace(".", ",")
                  }}
                </td>
                <td>{{ item.marca }}</td>
                <td class="text-xs-right">{{ item.codigoDeBarras }}</td>

                <v-btn color="#000" @click="showProduto(item)">Ver</v-btn>
              </template>
            </v-data-table>
          </div>
        </material-card>
      </v-flex>
      <v-flex md12>
        <material-card
          color="#baa995"
          flat
          full-width
          title="Ativar Produto"
          text="Ativar Produto"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-text-field
                  v-model="codigoDeBarras"
                  required
                  label="Codigo de barras"
                ></v-text-field>

                <v-flex xs12 text-xs-right>
                  <v-div v-if="!loadingAtivar">
                    <v-btn
                      class="mx-0 font-weight-light"
                      color="success"
                      @click="realizarAtualizacao('bottom')"
                      >Atualizar</v-btn
                    >
                  </v-div>
                  <v-div v-else>
                    <v-progress-circular
                      :size="50"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </v-div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>

      <v-snackbar
        color="#000"
        :bottom="bottom"
        :top="top"
        :left="left"
        :right="right"
        v-model="snackbar"
        dark
      >
        <v-icon color="#fff" class="mr-3">mdi-bell-plus</v-icon>
        <div>Produto atualizado com sucesso!</div>
        <v-icon size="16" @click="snackbar = false">mdi-close-circle</v-icon>
      </v-snackbar>

      <v-snackbar
        color="#BA0000"
        :bottom="bottom"
        :top="top"
        :left="left"
        :right="right"
        v-model="snackbarerror"
        dark
      >
        <v-icon color="#fff" class="mr-3">mdi-bell-plus</v-icon>
        <div>
          Verifique os dados e tente novamente
          <br />Verifique a conexão com a internet!
        </div>
        <v-icon size="16" @click="snackbarerror = false"
          >mdi-close-circle</v-icon
        >
      </v-snackbar>
    </v-layout>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>{{
          selecionado.nome
        }}</v-card-title>
        <v-card-text>
          Código de Barras: {{ selecionado.codigoDeBarras }}
          <br />
          Tamanho: {{ selecionado.tamanho }}
          <br />
          Valor de venda:
          {{ selecionado.valor_venda.toFixed(2).toString().replace(".", ",") }}
          <br />
          Data de criação: {{ selecionado.criado }}
        </v-card-text>
        <v-flex md12>
          <!-- <v-img
            height="200"
            width="200"
            :src="'data:image/jpeg;base64,' + selecionado.imagem"
          ></v-img> -->
        </v-flex>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogeditar" width="500">
      <v-card class="pa-md-3 mx-lg-auto">
        <v-card-title class="headline grey lighten-2 px-5" primary-title>{{
          selecionado.nome
        }}</v-card-title>
        <div class="px-5">
          <v-card-text class="px-0"
            >Código de Barras: {{ selecionado.codigoDeBarras }}</v-card-text
          >
          <v-text-field label="Nome" v-model="selecionado.nome"></v-text-field>
          <v-text-field
            label="Quantidade"
            v-model="selecionado.quantidade"
          ></v-text-field>
          <v-file-input
            name="arquivo"
            label="Inserir imagem"
            :change="arquivo"
          ></v-file-input>
          <v-text-field
            label="Tamanho"
            v-model="selecionado.tamanho"
          ></v-text-field>
          <v-text-field
            label="Marca"
            v-model="selecionado.marca"
          ></v-text-field>
          <v-select
            v-model="selecionado.visibilidade"
            :items="visibilidade_options"
          >
          </v-select>
          <v-text-field label="SKU" v-model="selecionado.sku"></v-text-field>
          <v-select
            v-model="select"
            :items="categorias"
            :rules="[(v) => !!v || 'Categoria necessária!']"
            label="Categoria"
            @change="getSubCategorias()"
            required
          ></v-select>

          <input
            type="file"
            id="file"
            ref="file"
            v-on:change="handleFileUpload()"
          />

          <v-select
            v-model="subselect"
            :items="subcategorias"
            :rules="[(v) => !!v || 'SubCategoria necessária!']"
            label="Subcategoria"
            required
          ></v-select>
          <v-text-field
            label="Valor de Atacado"
            v-model="selecionado.valor_atacado"
          ></v-text-field>
          <v-text-field
            label="Valor de Venda"
            v-model="selecionado.valor_venda"
          ></v-text-field>
          <v-text-field
            label="Valor Desconto"
            v-model="selecionado.valor_desconto"
          ></v-text-field>
          <v-div v-if="this.selecionado.image != null">
            <v-flex md12>
              <v-img
                :src="selecionado.image"
                aspect-ratio="1.5"
                contain
              ></v-img>
            </v-flex>
          </v-div>
        </div>

        <div>
          <h4 class="ml-4">Coleção</h4>
          <v-data-table :headers="headers" :items="items.colecao">
            <template slot="headerCell" slot-scope="{ header }">
              <span
                class="subheading font-weight-light text-success text--darken-3"
                v-text="header.text"
              />
            </template>
            <template slot="items" slot-scope="{ item }">
              <td v-if="item.url">
                <v-img :src="item.url"></v-img>
              </td>
              <td>{{ item.nome }}</td>
              <td>
                {{
                  item.preco.preco_venda.toFixed(2).toString().replace(".", ",")
                }}
              </td>
              <td>{{ item.marca }}</td>
              <td class="text-xs-right">{{ item.codigoDeBarras }}</td>

              <v-btn color="#000" @click="showProduto(item)">Ver</v-btn>
            </template>
          </v-data-table>
        </div>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="atualizar()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    pesquisa: "",
    loadingAtivar: false,
    dialog: false,
    dialogeditar: false,
    opcao: 0,
    codigoDeBarras: "",
    fotos: true,
    disponivel: true,
    visivel: true,
    tipodebusca: "",
    editar: {
      nome: "",
      preco_atacado: 0,
      preco_venda: 0,
      tamanho: "",
      imagem: "",
      marca: "",
      quantidade: 0,
    },
    headers: [
      {
        sortable: false,
        text: "Imagem",
        value: "url",
      },
      {
        sortable: false,
        text: "Nome",
        value: "nome",
      },
      {
        sortable: false,
        text: "Preço",
        value: "preco.preco_venda",
      },
      {
        sortable: false,
        text: "Marca",
        value: "marca",
      },
      {
        sortable: false,
        text: "Código de Barras",
        value: "codigoDeBarras",
        align: "right",
      },
      {
        sortable: false,
        text: "Opções",
        value: "opcoes",
      },
    ],
    visibilidade_options: [
      {
        text: "Visível",
        value: true,
      },
      {
        text: "Invisível",
        value: false,
      },
    ],
    snackbar: false,
    snackbarerror: false,
    categorias: [],
    subcategorias: [],
    arquivo: [],
    file: "",
    right: false,
    left: false,
    top: false,
    bottom: false,

    select: null,
    subselect: null,
    selecionado: {
      nome: "",
      codigoDeBarras: "",
      tamanho: "",
      valor_venda: 0,
      quantidade: 0,
      valor_atacado: 0,
      valor_desconto: 0,
      codigo: "",
      criado: "",
      _id: "",
      imagem: "",
    },
    items: [],
    items_busca: [],
  }),
  methods: {
    async getCategorias() {
      let getCategorias = await this.$http.get("/categorias");
      let categories = getCategorias.data.categorias;
      for (let e of categories) {
        e.text = e.nome;
        e.value = e._id;
      }

      this.categorias = categories;

      await this.getSubCategorias();
    },
    async getSubCategorias() {
      console.log("ENTROU");
      let categoria = this.select;
      if (categoria) {
        let res_subcategorias = await this.$http.get(
          `/subcategorias/categorias/admin/${categoria}`
        );
        let subcategorias = res_subcategorias.data;

        for (let e of subcategorias) {
          e.text = e.nome;
          e.value = e._id;
        }

        this.subcategorias = subcategorias;
      }
    },
    getProdutos() {
      this.$http
        .put("/produtos/all", {
          disponivel: this.disponivel,
          fotos: this.fotos,
          visivel: this.visivel,
        })
        .then((res) => {
          let produtos = res.data.produtos;

          for (let e of produtos) {
            if (e.image[0] && e.image[0].key) {
              e.url = `https://api-store.emporiodasmarcas.com.br/files/product/${e.image[0].key}`;
            }
          }

          this.items = produtos;
        })
        .catch((err) => {});
    },
    getProdutoEspecifico() {
      var codigo = "codigo";
      var tipo = "";
      if (this.opcao === 0) {
        codigo = "codigo";
        tipo = "c";
      } else if (this.opcao === 1) {
        codigo = "marca";
        tipo = "m";
      } else {
        codigo = "nome";
        tipo = "n";
      }

      this.$http
        .get(
          `produtos/search/itens/${this.pesquisa}?tipo=${tipo}&fts=${this.fotos}&disp=${this.disponivel}&vsl=${this.visivel}`
        )
        .then((res) => {
          let produtos = res.data.produtos;
          console.log("AQUI");
          for (let e of produtos) {
            if (e && e.image && e.image[0] && e.image[0].key) {
              e.url = `https://api-store.emporiodasmarcas.com.br/files/product/${e.image[0].key}`;
            }
          }
          this.items_busca = produtos;
        })
        .catch((err) => {
          console.log(err);
        });

      // this.$http
      //   .get(`produtos/${codigo}/${this.pesquisa}`)
      //   .then((res) => {
      //     let produtos = res.data.produtos;
      //     console.log("AQUI")
      //     for (let e of produtos) {
      //       if (e && e.image && e.image[0] && e.image[0].key) {
      //         e.url = `https://api-store.emporiodasmarcas.com.br/files/product/${e.image[0].key}`;
      //       }
      //     }
      //     this.items_busca = produtos;
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //   });
    },
    changeToNome() {
      this.opcao = 2;
      this.getProdutoEspecifico();
    },
    changeSearch() {
      switch (this.tipodebusca) {
        case "code":
          this.opcao = 0;
          // this.getProdutoEspecifico();
          break;
        case "brand":
          this.opcao = 1;
          // this.getProdutoEspecifico();
          break;
        case "name":
          this.opcao = 2;
          // this.getProdutoEspecifico();
          break;
        default:
          this.opcao = 0;
          // this.getProdutoEspecifico();
          break;
      }
    },
    buscarTipo() {
      this.getProdutoEspecifico();
    },
    changeToMarca() {
      this.opcao = 1;
      this.getProdutoEspecifico();
    },
    changeToCodigo() {
      this.opcao = 0;
      this.getProdutoEspecifico();
    },
    showProduto(item) {
      try {
        this.selecionado.image = null;

        this.selecionado.nome = item.nome;
        this.selecionado.marca = item.marca;
        this.selecionado.codigoDeBarras = item.codigoDeBarras;
        this.selecionado.tamanho = item.tamanho;
        this.selecionado.valor_venda = item.preco.preco_venda;
        this.selecionado.valor_atacado = item.preco.preco_atacado;
        this.selecionado.valor_desconto = item.preco.preco_desconto;
        this.selecionado.sku = item.sku;
        this.selecionado.quantidade = item.quantidade;
        this.select = item.categoria;
        this.selecionado.visibilidade = item.visibilidade;
        this.getSubCategorias();
        this.subselect = item.subcategoria;
        this.selecionado.criado = item.createdAt;
        this.selecionado._id = item._id;
        if (item.image && item.image.key) {
          this.selecionado.image = `https://api-store.emporiodasmarcas.com.br/files/product/${item.image.key}`;
        }
        this.dialogeditar = true;
      } catch (err) {}
    },
    atualizar() {
      let dados = new FormData();
      dados.append("nome", this.selecionado.nome);
      dados.append("tamanho", this.selecionado.tamanho);
      dados.append("marca", this.selecionado.marca);
      dados.append("sku", this.selecionado.sku);
      dados.append("quantidade", this.selecionado.quantidade);
      dados.append("categoria", this.select);
      dados.append("subcategoria", this.subselect);
      dados.append("visibilidade", this.selecionado.visibilidade);

      if (this.file) dados.append("file", this.file);
      dados.append("preco_atacado", parseFloat(this.selecionado.valor_atacado));
      if (this.selecionado.valor_desconto)
        dados.append(
          "preco_desconto",
          parseFloat(this.selecionado.valor_desconto)
        );
      dados.append("preco_venda", parseFloat(this.selecionado.valor_venda));

      this.$http
        .put(`produto/${this.selecionado._id}`, dados)
        .then((res) => {
          this.snackbar = true;
          this.file = "";
        })
        .catch((err) => {});
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    selectFile(event) {
      try {
        /*let file_ = file.target.files[0];
        

        this.arquivo = {
          file: file_,
          id: file_.name + (file_.size).toString(),
          name: file_.name,
          size: file_.size,
          url: null
        };*/

        let fi = [...event.target.files].map((file) => ({
          file: file,
          id: file.name + file.size.toString(),
          name: file.name,
          size: file.size,
          url: null,
        }));

        this.arquivo = this.arquivo.concat(fi);
      } catch (err) {}
    },
    async realizarAtualizacao(...args) {
      this.loadingAtivar = true
      this.top = false;
      this.bottom = false;
      this.left = false;
      this.right = false;

      for (const loc of args) {
        this[loc] = true;
      }

      if (this.codigoDeBarras == null || this.codigoDeBarras == "") {
        this.snackbarerror = true;
        this.loadingAtivar = false;
      } else {
        await this.$http
          .put(`/produto-ativar/${this.codigoDeBarras}`)
          .then((res) => {
            this.snackbar = true;
            this.loadingAtivar = false
          })
          .catch((err) => {
            this.loadingAtivar = false
            this.snackbarerror = true;
          });
      }
    },
  },
  beforeMount() {
    this.getProdutos();
    this.getCategorias();
  },
};
</script>

<style>
#selecao {
  background-color: #a9a9a9;
  padding: 10px;
  border-radius: 10px;
}
</style>